import styles from "./sButton.module.scss";
import SLoader from "./sLoader";

export default function SButton({
  content = "Button",
  type = "submit",
  isLoading = false,
  theme = "basic",
  size = "m",
  onClick = () => {},
  children,
}: {
  content?: string;
  type?: "submit" | "button" | "reset" | undefined;
  isLoading?: boolean;
  onClick?: React.MouseEventHandler | undefined;
  theme?: "basic" | "aura" | "ghost" | "negative" | "auraRounded" | undefined;
  size?: "m" | "s";
  children?: React.ReactNode;
}) {
  const buttonThemes = {
    basic: styles.basic,
    aura: styles.aura,
    ghost: styles.ghost,
    negative: styles.negative,
    auraRounded: styles.auraRounded,
  };

  const buttonSizes = {
    m: styles.normal,
    s: styles.small
  }

  return (
    <button
      className={[
        styles.sButton,
        isLoading && styles.loading,
        buttonThemes[theme],
        buttonSizes[size]
      ].join(" ")}
      type={type}
      onClick={(e) => {
        if (isLoading) return;
        onClick(e);
      }}
    >
      <span
        className={styles.contents}
        style={{
          opacity: isLoading ? 0 : 1,
        }}
      >
        { children && <div className={styles.iconSlot}>{children}</div>}
        {content}
      </span>
      {isLoading && (
        <div className={styles.loader}>
          <SLoader />
        </div>
      )}
    </button>
  );
}
