import styles from "@/styles/pages/Auth.module.scss";
import StreamonLogo from "@/components/Logo";
import SButton from "@/components/base/sButton";
import GoogleIcon from "@/components/icons/GoogleIcon";
import type {
  GetServerSidePropsContext,
} from "next";
import { getProviders, signIn } from "next-auth/react";
import { getServerSession } from "next-auth/next";
import { authOptions } from "./api/auth/[...nextauth]";

export default function Auth({ providers, session }: any) {
  
  return (
    <div className={styles.authPage}>
      <div className={styles.loginCard}>
        <StreamonLogo size={60} />
        <h1 className={styles.title}>Welcome to Streamon</h1>

        <div className={styles.authContent}>
          <div className={styles.disclaimer}>
            <p>
              By continuing you agree to Streamon's Terms of Service and Privacy
              Policy
            </p>
          </div>
          <SButton
            content="Continue with Google"
            theme="aura"
            onClick={() => {
              signIn(providers.google.id);
            }}
          >
            <GoogleIcon />
          </SButton>
        </div>
      </div>
    </div>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const providers = await getProviders();
  const session = await getServerSession(context.req, context.res, authOptions);
  if (session) {
    console.log(`redirecting to /`)
    return {
      redirect: {
        destination: "/",
        permanaent: false,
      },
    };
  }

  return {
    props: {
      providers,
      session,
    },
  };
}
