export default function StreamonLogo({ size }: { size: Number }) {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 1000 1000"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_75_152"
        maskUnits="userSpaceOnUse"
        x="196"
        y="176"
        width="608"
        height="648"
      >
        <path
          d="M776.966 193.207C773.227 187.566 764.396 190.377 763.669 197.107C757.429 254.844 724.623 269.786 687.764 292.39C684.692 294.273 683.583 298.194 685.175 301.428L725.689 383.714C727.67 387.737 732.928 388.853 736.374 385.986C832.119 306.331 806.952 238.456 776.966 193.207Z"
          fill="white"
        />
        <path
          d="M223.687 193.183C227.446 187.556 236.29 190.377 237.017 197.107C243.254 254.814 276.028 269.77 312.864 292.354C315.96 294.252 317.059 298.216 315.419 301.459L273.756 383.841C271.746 387.814 266.525 388.892 263.102 386.045C167.301 306.351 193.457 238.443 223.687 193.183Z"
          fill="white"
        />
        <path
          d="M420.51 411.199C420.51 400.249 432.448 393.486 441.832 399.119L635.504 515.39C644.618 520.862 644.618 534.078 635.504 539.549L441.832 655.82C432.448 661.454 420.51 654.69 420.51 643.741V411.199Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M500.054 230.939C336.38 230.939 203.696 363.7 203.696 527.469C203.696 691.239 336.38 824 500.054 824C663.728 824 796.411 691.239 796.411 527.469C796.411 363.7 663.728 230.939 500.054 230.939ZM500.758 770.469C634.496 770.469 742.912 661.99 742.912 528.174C742.912 394.358 634.496 285.878 500.758 285.878C367.02 285.878 258.603 394.358 258.603 528.174C258.603 661.99 367.02 770.469 500.758 770.469Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_75_152)">
        <rect
          x="96"
          y="106"
          width="807"
          height="807"
          fill="url(#paint0_linear_75_152)"
        />
        <path
          d="M695.465 300.824C737.186 286.026 760.347 249.106 769.716 222.602C770.866 219.349 775.699 219.696 775.409 223.135C771.808 265.955 737.137 316.907 716.297 341.413C715.283 342.606 713.523 342.788 712.332 341.771C700.484 331.657 693.851 314.734 693.492 303.562C693.452 302.319 694.293 301.24 695.465 300.824Z"
          fill="white"
          fillOpacity="0.32"
        />
        <path
          d="M252.902 367.106C188.691 314.47 198.924 251.638 217.712 215.384C219.29 212.34 223.664 214.353 223.11 217.737C213.464 276.606 257.906 327.402 286.05 348.818C287.559 349.967 287.7 352.209 286.219 353.393C275.898 361.642 262.342 366.163 255.358 367.718C254.498 367.91 253.583 367.665 252.902 367.106Z"
          fill="white"
          fillOpacity="0.23"
        />
        <path
          d="M640.5 454C487.7 475.2 413 598.167 431 670L427.5 688L379.5 622L387.5 347.5L640.5 454Z"
          fill="white"
          fillOpacity="0.19"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_75_152"
          x1="120"
          y1="246"
          x2="836"
          y2="724.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4021FF" />
          <stop offset="0.557292" stopColor="#D321AC" />
          <stop offset="1" stopColor="#FB0F39" />
        </linearGradient>
      </defs>
    </svg>
  );
}
